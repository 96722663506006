define(['app', 'jquery'], function(app, $) {
  
  return {
    /**
     * Initiation function
     * @param config
     */
    init: function(config) {

      if (typeof config !== 'undefined') {

        // Check values and set defaults if necessary
        config.globalTabs ? true : config.globalTabs = $('.tabs'),
        config.tabHeading ? true : config.tabHeading = '',
        config.tabPanel ? true : config.tabPanel = 'tabs__panel',
        config.tabBody ? true : config.tabBody = '',
        config.speed ? true : config.speed = 0,
        config.calculateContentHeight ? true : config.calculateContentHeight = false,
        config.royalSlider ? true : config.royalSlider = true;

        // Initiate click events
        this._tabStart(config);
      }
    },

    /**
     * Dynamically set first tab and initiate click events
     * @param config
     */
    _tabStart: function(config) {

      var self = this;
      var tabBlocks = document.querySelectorAll(config.globalTabs);
      var childTabHeadings = null;
      var childTabContents = null;
      var firstTab = null;
      var firstTabContent = null;
      var tabCountAll = 0;
      var selectedRsContainer = null;

      // find all tab sections on a page
      for (var i = 0; i < tabBlocks.length; i++) {

        childTabHeadings = tabBlocks[i].querySelectorAll(config.tabHeading);
        childTabContents = tabBlocks[i].querySelectorAll(config.tabBody);

        for (var ii = 0; ii < childTabHeadings.length; ii++) {

          if (ii > 0) {
            childTabContents[ii].style.display = 'none';
          }


          // todo create a function to remove the tabs from the page if they are for some reason empty
          // count tab contents

          selectedRsContainer = childTabContents[ii].querySelector('.royal-slider') || childTabContents[ii].querySelector('.pTabbedProductLists__wrapper');

          if (selectedRsContainer !== null) {
            tabCountAll += parseInt(selectedRsContainer.children.length);
          }
        }

        if (tabCountAll > 0) {
          // tabs have children so show them
          tabBlocks[i].style.visibility = 'visible';
        } else if (tabCountAll === 0) {
          //tabBlocks are empty so lets remove the block
          tabBlocks[i].remove();
        }

        childTabHeadings[0].style.display = 'block';
        childTabContents[0].style.display = 'block';
        childTabHeadings[0].className = childTabHeadings[0].className + ' active';
        firstTab = childTabHeadings[0].parentNode;
        firstTabContent = firstTab.querySelector(config.tabBody);
        firstTabContent.style.display = 'block';

        if (config.calculateContentHeight) {
          self._tabContentHeight(firstTab, config);
        }
      }


      this._tabsClick(config);
    },

    /*
     * Set clicked tab to display and set rest to hide
     * @param config
     */

    _tabsClick: function(config) {
      var self = this;

      app.element.on('click', function() {

        var classNamesList = this.className;
        var tabPanelName = (config.tabPanel).replace(/./, '');
        var clickedTabPanel = {};
        var clickedTabPanelClasses = '';
        var activeTab = null;
        var activeTabClassName = '';
        var activeTabClassNameTemp = '';
        var activeTabParent = null;
        var activePanelContent = null;
        var mainTabsContainer = null;
        var allPanelContent = null;
        var i = 0;

        if (classNamesList.indexOf('active') < 0) {
          clickedTabPanel = this.parentNode;
          clickedTabPanelClasses = clickedTabPanel.className;

          if (clickedTabPanelClasses.indexOf(tabPanelName) > -1) {
            mainTabsContainer = clickedTabPanel.parentNode;

            if (mainTabsContainer) {

              activeTab = mainTabsContainer.querySelector('.active');

              if (!activeTab) {
                return false;
              }

              activeTabClassName = activeTab.className;

              if (activeTabParent) {
                activeTabParent = activeTab.parentNode;
                activePanelContent = activeTabParent.querySelector(config.tabBody);
                activePanelContent.style.display = 'none';
              }

              activeTabClassNameTemp = activeTabClassName.replace(/active/gi, '');
              activeTab.className = '';
              activeTab.className = activeTabClassNameTemp;

            }

            this.className = classNamesList += ' active';
            activeTabParent = this.parentNode;

            if (activeTabParent) {
              activePanelContent = activeTabParent.querySelector(config.tabBody);

              //hide all the other tab panel contents
              allPanelContent = mainTabsContainer.querySelectorAll(config.tabBody);

              for (i = 0; i < allPanelContent.length; i++) {
                allPanelContent[i].style.display = 'none';
              }
              activePanelContent.style.display = 'block';
            }
          }
        }

        if (config.calculateContentHeight) {
          // check if falsey as above, in this case it protects against null as well (if the element has disappeared for whatever reason)
          if (this.parentNode) {
            self._tabContentHeight(this.parentNode, config);
          }
        }
      }, document.querySelectorAll(config.tabHeading));

    },

    /*
     * Find height of inner elements
     */

    _tabContentHeight: function(targetPanel, config) {

      //var tabContainer = targetPanel.parentNode;
      var panelContent = targetPanel.querySelector(config.tabBody);
      var tab = targetPanel.querySelector(config.tabHeading);
      var tabHeight = tab.scrollHeight || 0;
      var items = panelContent.querySelectorAll('.item');
      var itemHeight = 0;
      var itemHeightArray = [];
      var itemArrayLength = 0;
      var largestHeight = 0;
      var contentContainerHeight = '400'; //fallback height of tab contents
      var tabPanelHeight = '400px'; // fallback height for tabpanel
      var i;

      // if product carousel then there will be .item blocks
      if (items.length > 0) {


        // array of carousel item heights
        for (i = 0; i < items.length; i++) {
          itemHeight = items[i].offsetHeight;
          itemHeightArray.push(itemHeight);
        }

        itemArrayLength = itemHeightArray.length + 1;

        for (i = 0; i < itemArrayLength; i++) {
          if (itemHeightArray[i] > largestHeight) {
            largestHeight = itemHeightArray[i];

          }
        }

        if (items.length > 1) {
          for (i = 0; i < items.length; i++) {
            items[i].style.height = largestHeight + 'px';
          }
        }

      } else {
        //if no items then we aren't a carousel
        //this will be used on product pages to change the height of the content boxes
        /*
         var contentPanel = $panelContent.children[0],
         theHeight = contentPanel.scrollHeight;
         */
      }

      contentContainerHeight = largestHeight; // starting height

      if (config.royalSlider) {

        var rSlider = targetPanel.querySelector('.royal-slider');

        if (rSlider) {
          rSlider.style.height = contentContainerHeight + 'px';
        }
      }

      // get height of panel
      tabPanelHeight = (contentContainerHeight + tabHeight + (panelContent.offsetHeight - contentContainerHeight)) + 'px';

      targetPanel.style.height = tabPanelHeight;
    },

    _mobileScrollTab: function() {}
  };
});
